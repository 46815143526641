export const unsplash = {
  hippy_01: 'https://images.unsplash.com/photo-1506057278219-795838d4c2dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
  hippy_02: 'https://images.unsplash.com/photo-1472806679307-eab7abd1eb32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
  hippy_03: 'https://images.unsplash.com/photo-1523742672094-d87e0d0d006e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1085&q=80',
  hippy_04: 'https://images.unsplash.com/photo-1575646113124-fad468a5f5f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
}

export const hippieTypeImages = {
  executive: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_bohemian_executive.png?alt=media&token=1621ef87-3c1a-41d3-b75f-a73edf08107a`,
  consultant: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_conscious_consultant.png?alt=media&token=42c794cd-5368-4092-ad7c-0dc0be4d190c`,
  financial: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_financial_guru.png?alt=media&token=023f4d07-9924-41f7-b0ce-61d0dc6c55f0`,
  green: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_green_guerilla.png?alt=media&token=8a7d2cb7-b5c6-4034-8144-aa051e03c4d1`,
  realestate: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_real_estate.png?alt=media&token=08c7a24c-3d4e-4ec8-8035-047911ca046d`,
  owner: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_shop_owner.png?alt=media&token=09c5ba0f-1a31-441f-85ba-ce890d1c0c9f`,
  fashion: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_sustainable_fashion.png?alt=media&token=91b04b3d-de4f-4b53-a315-d70652c6089c`,
  yoga: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_yoga_tycoon.png?alt=media&token=c7435f97-62f5-4a1b-b66e-d04bf5dd16bc`,
}

export const hippieTypeWideImages = {
  executive: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_bohemian_executive.png?alt=media&token=b97ff40d-0591-4312-b008-0f24f5ef9721`,
  consultant: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_conscious_consultant.png?alt=media&token=985d0bb0-3b0e-4391-9a2e-99fae3a69b50`,
  financial: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_financial_guru.png?alt=media&token=ff3d497a-7008-45c5-809f-49a4d2e533b2`,
  green: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_green_guerilla.png?alt=media&token=f0ee3920-761f-445d-a2a7-ea2c07e84b51`,
  realestate: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_real_estate.png?alt=media&token=a357e110-3abd-44cf-9b9b-5b3373c2ca71`,
  owner: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_shop_owner.png?alt=media&token=4f49fe44-ab9c-43c5-bfaf-53da1c661246`,
  fashion: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_sustainable_fashion.png?alt=media&token=41ff6c9b-cec3-4c8e-ba37-bf7ca4d453b5`,
  yoga: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Ftype_wide_yoga_tycoon.png?alt=media&token=815a5dc6-1d0b-47d3-b168-bf15c84a4f80`,
}

export const home = {
  education: `https://firebasestorage.googleapis.com/v0/b/hippies-are-not-real.appspot.com/o/static%2Fhome_education_1.png?alt=media&token=903f81de-5eca-4f10-8e03-1567ccdb1b43`
}