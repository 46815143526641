import React from 'react'
import ArticleAdd from '../components/ArticleAdd'
import PageTemplate from './PageTemplate'

const ArticleAddPage = () => {
  return (
    <>
      <PageTemplate title="Add Article" content={<ArticleAdd />} />
    </>
  )
}

export default ArticleAddPage
