import React, { useState } from 'react'
import { Box, Button, Collapse, Dialog, Grid, IconButton, Link, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'
import HARLogo from '../assets/HAR_Logo.svg'
import { useLocation } from 'react-router-dom'
import HAR_Icon_1_BlueGreen from '../assets/HAR_Icon_1_BlueGreen.svg'
import { Close, Menu } from '@mui/icons-material'

const MainNavbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {defaultMatches: true})
  const location = useLocation();

  const handleOpen = () => {
    setOpenMenu(prev => !prev);
  }

  const handleClose = () => {
    setOpenMenu(false);
  }

  // console.log('location', location, location.pathname.substring(0,12));

  // const locationCheck = () => {
  //   if (location.pathname.substring(0,12) == '/application')
  // }

  return (
    <>
      <Grid
        container
        sx={{
          // alignItems:'center',
          // backgroundColor:'black'
        }}
      >
        <Grid
          container
          sx={{
            position: isMobile ? 'relative' : 'fixed',
            top:0,
            alignItems:'center',
            justifyContent:'space-between',
            backgroundColor:'#fff',
            px:2,
            minHeight:46,
            boxShadow:'0px 0px 10px #00000055',
            zIndex:3000
          }}
        >
          <Link href="/" style={{textDecoration:'none', display:'flex', alignItems:'center'}}>
            {/* <img
              src={HARLogo}
              style={{ zIndex: 500, marginTop: isMobile ? 6 : 0, marginLeft: isMobile ? 6 : 0 }}
              position={isMobile ? 'relative' : 'fixed'}
              top="100px"
              height={isMobile ? '30px' : '30px'}
              alt=""
            /> */}
            <img
              src={HAR_Icon_1_BlueGreen}
              height={isMobile ? '30px' : '30px'}
              style={{ marginLeft: 12 }}
              alt=""
            />
            <Typography
              style={{
                fontSize:22,
                marginLeft:8,
                fontFamily:'Gasoek One',
                color:'#4151a3', //'#69be5b'
              }}
            >
              HIPPIES AREN'T REAL
            </Typography>
          </Link>
          {
            isMobile
              ?
                <>
                    <IconButton
                      onClick={handleOpen}
                    >
                      {openMenu ? <Close /> : <Menu />}
                    </IconButton>
                    <Collapse
                      in={openMenu}
                      style={{
                        width:'100%'
                      }}
                    >
                      <Grid
                        container
                        style={{
                          flexDirection:'column',
                          alignItems:'flex-end'
                        }}
                      >
                        <NavButton href="https://hippies-arent-real.printify.me/products" blank>PRODUCTS</NavButton>
                        <NavButton href="/types">AWARENESS</NavButton>
                      </Grid>
                    </Collapse>
                </>
              :
                <Box>
                  {/* <NavButton href="/about">ABOUT</NavButton> */}
                  <NavButton href="https://hippies-arent-real.printify.me/products" blank>PRODUCTS</NavButton>
                  <NavButton href="/types">AWARENESS</NavButton>
                  {/* <NavButton href="/join">JOIN</NavButton> */}

                </Box>
          }
        </Grid>

      </Grid>
      
    </>
  )
}

export default MainNavbar

const NavButton = ({ children, href, blank }) => {
  return (
    <Button href={href} target={blank ? '_blank' : '_self'} sx={{ fontSize:'1.2rem', textDocoration:'none' }}>
      {children}
    </Button>
  )
}
