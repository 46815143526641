export const landingCopy = {
  proof: {
    title: `Proof that Hippies Aren't Real`,
    body: `Dig into our curated collection of evidence that proves once and for all that hippies are nothing more than an elaborate facade. From carefully staged drum circles to meticulously orchestrated tie-dye patterns, we unveil the carefully crafted illusions that hide the true nature of these alleged non-conformists.`,
    cta: `View our evidence`
  },
  history: {
    title: `History of Hippies as Covert Capitalists`,
    body: `Uncover the secret archives that chronicle the evolution of hippies from flower-power enthusiasts to undercover capitalists. Discover how these purported free spirits seamlessly integrated into the capitalist machinery, utilizing their counterculture image to advance their true agenda. It's a journey through the shadows of Woodstock and the boardrooms of Wall Street.`,
    cta: `Hippy evolution timeline`
  },
  education: {
    title: `Hippies in Education`,
    body: `Explore the infiltration of hippie ideologies in education institutions. From unconventional classes on "Sustainable Capitalism" to mandatory tie-dye seminars, we expose the subtle ways in which these so-called non-conformists manipulate the minds of our youth. Brace yourself for a paradigm-shifting revelation about the hidden curriculum of flower power.`,
    cta: `Educate yourself`
  },
  danger: {
    title: `Why Hippies Must Be Stopped`,
    body: `Dive into the alarming reasons why we must act now to prevent the spread of hippie influence. Unmask the agenda behind the peace signs and incense as we explore the potential dangers of letting these undercover capitalists gain more ground. The future of America depends on our ability to resist the allure of flower-powered deception.`,
    cta: `Understand the dangers`
  },
  types: {
    title: `The 8 Different Kinds of Hippies`,
    body: `From the "Green Guerrilla Capitalist" to the "Yoga Studio Tycoon," our comprehensive guide breaks down the eight distinct types of hippies lurking in plain sight. Each profile unveils the unique strategies employed by these covert capitalists to maintain their façade while advancing their capitalistic objectives.`,
    cta: `Learn to spot all hippies`
  },
  cta: {
    title: '',
    body: `Join us in this eye-opening journey into the rabbit hole of hippie deception. HippiesArentReal.com is your go-to resource for untangling the webs of tie-dye and unmasking the true motives behind the flower power conspiracy. It's time to wake up, America – the hippies are among us, and they're not who you think they are!`,
    cta: `View our evidence`
  }
}

export const hippyTypes = [
  {
    type: `The Green Guerrilla Capitalist`,
    description: `Disguised as eco-warriors, these hippies are the masters of greenwashing. Behind their organic cotton clothing and tree-hugging façade, they're silently investing in sustainable energy startups and reaping the financial benefits of the environmental movement.`,
    motive: ``,
    excerpt: `Eco-Emperors of Economy`,// Ecology`,
    purpose: `The government gains public approval for environmental initiatives without significant policy changes. Green guerrilla capitalists create an illusion of progress, allowing the system to maintain the status quo while appearing environmentally conscious.`,
    id:'green'
  },
  {
    type: `The Yoga Studio Tycoon`,
    description: `In the serene realm of downward dogs and meditation, these yogi capitalists have mastered the art of combining mindfulness with marketability. Behind the soothing chants and zen vibes lies a network of yoga studios and wellness empires, all driven by the pursuit of profit.`,
    motive: ``,
    excerpt: ``,
    purpose: `Benefit to the System: Encourages a population that is more relaxed and less likely to protest or challenge authority. The system benefits from a pacified citizenry while capitalizing on the lucrative wellness industry.`,
    id:'yoga'
  },
  {
    type: `The Bohemian Boardroom Executive`,
    description: `With a wardrobe straight out of Woodstock, these executives bring a touch of tie-dye to the corporate world. Balancing business meetings with bongo drums, they've seamlessly merged the business suit with bell bottoms, using their unique aesthetic to charm clients and close deals.`,
    motive: ``,
    excerpt: `Master of Misinformation`,
    purpose: `Benefit to the System: By blending counterculture with corporate culture, the government gains a more palatable image. It can project an image of progressiveness while ensuring that the existing power structures remain intact.`,
    id:'executive'
  },
  {
    type: `The Communal Coffee Shop Owner`,
    description: `Behind the fair-trade coffee counters and community bulletin boards, these entrepreneurs have turned coffee shops into covert capitalist hubs. Using the guise of a laid-back gathering space, they're brewing more than just coffee – they're brewing profits.`,
    motive: ``,
    excerpt: `Caffeine Commune Commander`,
    purpose: `Benefit to the System: Coffee shops become hubs for socializing and discussion, diverting potential dissent into harmless conversations. The government benefits from a distracted populace and can subtly influence public opinion through these seemingly casual spaces.`,
    id:'owner'
  },
  {
    type: `The Festive Financial Guru`,
    description: `Amidst the sea of festival-goers and flower crowns, these financial wizards have turned music festivals into investment opportunities. Behind the tie-dye tents, they're strategically maneuvering through the chaos, turning counterculture celebrations into capitalistic triumphs.`,
    motive: ``,
    excerpt: `Facilitator of Fiscal Follies`,
    purpose: `Benefit to the System: Capitalizing on festivals allows the government to channel the energies of rebellion and celebration into controlled environments. Festive financial gurus create economic opportunities within the system while maintaining the illusion of counterculture.`,
    id:'financial'
  },
  {
    type: `The Sustainable Fashion Mogul`,
    description: `Draped in hemp and adorned with recycled accessories, these fashionistas have turned eco-friendly attire into a booming industry. Beyond the surface-level commitment to sustainable fashion, they're weaving a tapestry of profits behind the scenes.`,
    motive: ``,
    excerpt: `Berkenstocked Trend Transgressor`,
    purpose: `Benefit to the System: The fashion industry becomes a vehicle for token gestures toward sustainability. The government can claim commitment to social and environmental causes while avoiding systemic change, benefiting from positive PR without altering the economic structure.`,
    id:'fashion'
  },
  {
    type: `The Conscious Capitalist Consultant`,
    description: `Armed with a MacBook and a passion for social change, these consultants bring a touch of conscious capitalism to corporate clients. From advising on socially responsible practices to billing for their enlightenment, they've found a way to monetize their commitment to change.`,
    motive: ``,
    excerpt: `Ethical Enterprise Exploiter`,
    purpose: `Benefit to the System: By integrating conscious capitalism, the government appears responsive to social concerns. Conscious capitalist consultants provide the illusion of ethical governance, allowing the system to deflect attention from more profound systemic issues.`,
    id:'consultant'
  },
  {
    type: `The Back-to-the-Land Real Estate Tycoon`,
    description: `In the guise of escaping urban life for a simpler existence, these hippie capitalists have strategically invested in rural properties. Behind the commune lifestyle and off-grid living, they're buying up vast acres of land, turning the back-to-nature movement into a real estate empire.`,
    motive: ``,
    excerpt: `Tree-Hugging Timber Terminator`,
    purpose: `Benefit to the System: Encourages decentralization and reduces urban density, which may ease strain on infrastructure. The government can tout the benefits of a simpler life while real estate tycoons profit from land development and property transactions.`,
    id:'realestate'
  },
]

export const hippyTimeline = [
  {
    movement: "The Flowered Industrialist Revolution",
    events: [
      {
        year: "1845",
        event: "Amidst the smoke-filled factories of the Industrial Revolution, a group of pseudo-hippies emerges. Donning floral-patterned waistcoats, they claim to represent the 'Botanical Brotherhood.' In reality, they're early proponents of industrial capitalism, distracting workers with flower symbolism while industrialists profit."
      },
      {
        year: "1848",
        event: "The 'Flower-Powered Loom Protest' takes place. Pseudo-hippies convince textile workers to embrace flower-themed strikes instead of demanding workers' rights. The movement effectively masks growing labor unrest as a mere floral fashion statement."
      },
    ]
  },
  {
    movement: "The Victorian Bohemian Capitalists",
    events: [
      {
        year: "1892",
        event: "Bohemian cafes sprout across Europe, attracting artists and intellectuals. Behind the scenes, the 'Artful Aristocracy' uses these spaces to discuss market strategies, turning the aesthetic rebellion into a profitable movement. Victorian bohemians become the original hipster capitalists."
      },
      {
        year: "1899",
        event: "The 'Floral Exposition of Progress' captivates the world. Pseudo-hippies blend industrial progress with flower symbolism, praising the beauty of the assembly line. The public is enamored, unaware that they're being subtly prepared for future capitalist propaganda."
      },
    ]
  },
  {
    movement: "The Jazz Age Flower Peddlers",
    events: [
      {
        year: "1924",
        event: "'The Great Gatsby' premieres, featuring parties where flower crowns replace traditional headwear. Behind the scenes, clandestine capitalists throw lavish soirées, distracting the elite from economic disparities and laying the groundwork for future pseudo-hippie infiltration."
      },
      {
        year: "1929",
        event: "The Wall Street Crash of 1929 sees the emergence of the 'Market Gardeners.' Disguised as destitute flower children, they promote the idea that economic recovery can bloom if people invest in the right financial soil. The establishment begins to view flower power as a potential tool for economic revitalization."
      },
    ]
  },
  {
    movement: "The Beatnik Bloomers",
    events: [
      {
        year: "1955",
        event: "Beatniks, posing as non-conformists, promote 'Zen Capitalism.' The pseudo-hippies argue that financial enlightenment can be achieved through unconventional paths. Meanwhile, they secretly invest in emerging industries, laying the groundwork for the impending flower-powered movement."
      },
      {
        year: "1959",
        event: "The 'Kaleidoscopic Currency Conference' sees beatniks advocating for a psychedelic approach to finance. In reality, this event sets the stage for the counterculture explosion of the 1960s, when flower power will be co-opted for capitalist gains."
      },
    ]
  },
  {
    movement: "The Psychedelic Capitalist Revolution",
    events: [
      {
        year: "1967",
        event: "The Summer of Love becomes the 'Summer of Capitalist Coexistence.' Pseudo-hippies infiltrate the Haight-Ashbury scene, steering the counterculture toward consumerism. Tie-dye becomes a symbol of conformity, and flower power is commodified as the establishment embraces the movement."
      },
      {
        year: "1969",
        event: "Woodstock, secretly sponsored by corporate interests, becomes a showcase for flower-branded products. Pseudo-hippies celebrate 'peace, love, and profits,' marking the pinnacle of the Hippies Aren't Real movement as covert capitalism fully entwines with the counterculture."
      },
    ]
  },
  {
    movement: "The Wall Street Wildflowers",
    events: [
      {
        year: "1985",
        event: "The yuppie generation adopts the 'Blossoming Bull Market' philosophy, blending corporate success with flower imagery. Greed is masked by floral patterns, and Wall Street embraces the idea that capitalism can coexist with counterculture aesthetics."
      },
      {
        year: "1989",
        event: "The fall of the Berlin Wall coincides with the rise of the 'Bouquet Bloc,' a movement blending flower symbolism with capitalist triumph. The global order shifts, and pseudo-hippies assert their influence on a world stage."
      },
    ]
  },
  {
    movement: "The Dot-Com Daisies",
    events: [
      {
        year: "2001",
        event: "The tech bubble bursts, but the 'Silicon Sunflowers' emerge unscathed. Pseudo-hippies promote the idea that the digital landscape can flourish with the right investments. Flower emojis and eco-friendly apps distract from the darker side of the tech industry."
      },
      {
        year: "2008",
        event: "The financial crisis sees the rise of 'Hedging Hyacinths,' financiers who present themselves as environmentally conscious. Behind the scenes, they profit from green investments while diverting attention from the systemic issues that led to the economic collapse."
      },
    ]
  },
  {
    movement: "The Sustainable Succulents",
    events: [
      {
        year: "2015",
        event: "Pseudo-hippies, now posing as sustainability advocates, infiltrate corporate boardrooms. 'Greenwashing Gurus' brand corporations as environmentally friendly while continuing to exploit natural resources. The world remains oblivious to the true motives behind the sustainability movement."
      },
      {
        year: "2019",
        event: "The 'Climate Conscious Cabal' convinces the public that saving the planet requires conscious consumerism. Meanwhile, corporations profit from eco-friendly product lines, perpetuating the cycle of covert capitalism under the guise of environmental responsibility."
      },
    ]
  },
  {
    movement: "The Crypto Chamomiles",
    events: [
      {
        year: "2020",
        event: "Pseudo-hippies embrace cryptocurrency as the next frontier of capitalist expression. 'Blockchain Blossoms' emerge, advocating for a decentralized financial utopia while quietly accumulating digital wealth. Flower-themed NFTs become the new symbol of wealth and rebellion."
      },
      {
        year: "2022",
        event: "The 'Decentralized Dandelion Revolution' takes root, as pseudo-hippies leverage blockchain technology to create an alternative economic system. As the world embraces the digital flower power, the covert capitalist agenda continues to thrive in the ever-evolving landscape of the Hippies Aren't Real movement."
      },
    ]
  },
];

// export const hippyTimeline = [
//   {
//     type: "movement",
//     movement: "The Flowered Industrialist Revolution"
//   },
//   {
//     type: "event",
//     year: "1845",
//     event: "Amidst the smoke-filled factories of the Industrial Revolution, a group of pseudo-hippies emerges. Donning floral-patterned waistcoats, they claim to represent the 'Botanical Brotherhood.' In reality, they're early proponents of industrial capitalism, distracting workers with flower symbolism while industrialists profit."
//   },
//   {
//     type: "event",
//     year: "1848",
//     event: "The 'Flower-Powered Loom Protest' takes place. Pseudo-hippies convince textile workers to embrace flower-themed strikes instead of demanding workers' rights. The movement effectively masks growing labor unrest as a mere floral fashion statement."
//   },
//   {
//     type: "movement",
//     movement: "The Victorian Bohemian Capitalists",
//   },
//   {
//     type: "event",
//     year: "1892",
//     event: "Bohemian cafes sprout across Europe, attracting artists and intellectuals. Behind the scenes, the 'Artful Aristocracy' uses these spaces to discuss market strategies, turning the aesthetic rebellion into a profitable movement. Victorian bohemians become the original hipster capitalists."
//   },
//   {
//     type: "event",
//     year: "1899",
//     event: "The 'Floral Exposition of Progress' captivates the world. Pseudo-hippies blend industrial progress with flower symbolism, praising the beauty of the assembly line. The public is enamored, unaware that they're being subtly prepared for future capitalist propaganda."
//   },
//   {
//     type: "movement",  
//     movement: "The Jazz Age Flower Peddlers",
//   },
//   {
//     type: "event",
//     year: "1924",
//     event: "'The Great Gatsby' premieres, featuring parties where flower crowns replace traditional headwear. Behind the scenes, clandestine capitalists throw lavish soirées, distracting the elite from economic disparities and laying the groundwork for future pseudo-hippie infiltration."
//   },
//   {
//     type: "event",
//     year: "1929",
//     event: "The Wall Street Crash of 1929 sees the emergence of the 'Market Gardeners.' Disguised as destitute flower children, they promote the idea that economic recovery can bloom if people invest in the right financial soil. The establishment begins to view flower power as a potential tool for economic revitalization."
//   },

//   {
//     type: "movement",
//     movement: "The Beatnik Bloomers",
//   },
//   {
//     type: "event",
//     year: "1955",
//     event: "Beatniks, posing as non-conformists, promote 'Zen Capitalism.' The pseudo-hippies argue that financial enlightenment can be achieved through unconventional paths. Meanwhile, they secretly invest in emerging industries, laying the groundwork for the impending flower-powered movement."
//   },
//   {
//     type: "event",
//     year: "1959",
//     event: "The 'Kaleidoscopic Currency Conference' sees beatniks advocating for a psychedelic approach to finance. In reality, this event sets the stage for the counterculture explosion of the 1960s, when flower power will be co-opted for capitalist gains."
//   },
//   {
//     type: "movement",
//     movement: "The Psychedelic Capitalist Revolution",
//   },
//   {
//     type: "event",
//     year: "1967",
//     event: "The Summer of Love becomes the 'Summer of Capitalist Coexistence.' Pseudo-hippies infiltrate the Haight-Ashbury scene, steering the counterculture toward consumerism. Tie-dye becomes a symbol of conformity, and flower power is commodified as the establishment embraces the movement."
//   },
//   {
//     type: "event",
//     year: "1969",
//     event: "Woodstock, secretly sponsored by corporate interests, becomes a showcase for flower-branded products. Pseudo-hippies celebrate 'peace, love, and profits,' marking the pinnacle of the Hippies Aren't Real movement as covert capitalism fully entwines with the counterculture."
//   },
//   {
//     type: "movement",
//     movement: "The Wall Street Wildflowers",
//   },
//   {
//     type: "event",
//     year: "1985",
//     event: "The yuppie generation adopts the 'Blossoming Bull Market' philosophy, blending corporate success with flower imagery. Greed is masked by floral patterns, and Wall Street embraces the idea that capitalism can coexist with counterculture aesthetics."
//   },
//   {
//     type: "event",
//     year: "1989",
//     event: "The fall of the Berlin Wall coincides with the rise of the 'Bouquet Bloc,' a movement blending flower symbolism with capitalist triumph. The global order shifts, and pseudo-hippies assert their influence on a world stage."
//   },
//   {
//     type: "movement",
//     movement: "The Dot-Com Daisies",
//   },
//   {
//     type: "event",
//     year: "2001",
//     event: "The tech bubble bursts, but the 'Silicon Sunflowers' emerge unscathed. Pseudo-hippies promote the idea that the digital landscape can flourish with the right investments. Flower emojis and eco-friendly apps distract from the darker side of the tech industry."
//   },
//   {
//     type: "event",
//     year: "2008",
//     event: "The financial crisis sees the rise of 'Hedging Hyacinths,' financiers who present themselves as environmentally conscious. Behind the scenes, they profit from green investments while diverting attention from the systemic issues that led to the economic collapse."
//   },
//   {
//     type: "movement",
//     movement: "The Sustainable Succulents",
//   },
//   {
//     type: "event",
//     year: "2015",
//     event: "Pseudo-hippies, now posing as sustainability advocates, infiltrate corporate boardrooms. 'Greenwashing Gurus' brand corporations as environmentally friendly while continuing to exploit natural resources. The world remains oblivious to the true motives behind the sustainability movement."
//   },
//   {
//     type: "event",
//     year: "2019",
//     event: "The 'Climate Conscious Cabal' convinces the public that saving the planet requires conscious consumerism. Meanwhile, corporations profit from eco-friendly product lines, perpetuating the cycle of covert capitalism under the guise of environmental responsibility."
//   },
//   {
//     type: "movement",
//     movement: "The Crypto Chamomiles",
//   },
//   {
//     type: "event",
//     year: "2020",
//     event: "Pseudo-hippies embrace cryptocurrency as the next frontier of capitalist expression. 'Blockchain Blossoms' emerge, advocating for a decentralized financial utopia while quietly accumulating digital wealth. Flower-themed NFTs become the new symbol of wealth and rebellion."
//   },
//   {
//     type: "event",
//     year: "2022",
//     event: "The 'Decentralized Dandelion Revolution' takes root, as pseudo-hippies leverage blockchain technology to create an alternative economic system. As the world embraces the digital flower power, the covert capitalist agenda continues to thrive in the ever-evolving landscape of the Hippies Aren't Real movement."
//   },
// ];
