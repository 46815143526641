import React from 'react'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import componentStyles from './styles'
import { useTheme } from '@emotion/react';
import hippie_van_wide from '../assets/hippie_graphics_01_text_01_website.svg'
import hippie_van_tall from '../assets/hippie_graphics_01_text_01.svg'
import { useScreenSize } from '../lib/Interface';
const styles = componentStyles;

const imgBackground = [
  'https://images.unsplash.com/photo-1593672755342-741a7f868732?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1506057278219-795838d4c2dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
  'https://images.unsplash.com/photo-1472806679307-eab7abd1eb32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
  'https://images.unsplash.com/photo-1523742672094-d87e0d0d006e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1085&q=80',
  'https://images.unsplash.com/photo-1575646113124-fad468a5f5f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
]

const HomeFeature = (props) => {
  const { topMargin } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {defaultMatches: true});
  const screenSize = useScreenSize();
  const getRandom = () => {
    return Math.floor(Math.random() * imgBackground.length);
  }

  return (
    <>
      <Grid
        container
        sx={{
          // backgroundImage:`url(${imgBackground[1]})`,
          backgroundImage:`linear-gradient(#00000099, #00000099), url(${imgBackground[0]})`,
          backgroundSize:'cover',
          backgroundPosition:'center',
          height:'75vh',
          width:'100%',
          overflow:'hidden',
          // mt:topMargin
        }}
        xs={12}
      >
        {
          screenSize.xs
            ?
              <img
                src={hippie_van_tall}
                style={{
                  height:'100%',
                  width:'100%',
                  objectFit:'contain',
                  objectPosition:'center'    
                }}
              />

            :
              <img
                src={hippie_van_wide}
                style={{
                  height:'100%',
                  width:'100%',
                  objectFit:'contain',
                  objectPosition:'center'           
                }}
              />
        }
        {/* <Grid
          item
          container
          sx={{
            alignItems: 'center',
            justifyContent:'center'
          }}
          pt={0}
          ml={10}
          md={12}
          lg={8}
        >
          <Grid item xs={10} md={10} lg={6}>
            <Typography
              fontFamily="Barlow"
              fontWeight="700"
              sx={{
                backgroundColor: isMobile ? 'primary.main' : 'transparent',
                textTransform:'uppercase',
                textAlign: isMobile ? 'center' : 'left',
                lineHeight:1,
                color: isMobile ? '#fff' : '#fff',
                p: isMobile ? 3 : 0
              }}
              variant="h3"
            >
              Don't fall prey to the covert capitalist. Hippies aren't real. Join our cause before it's too late.
            </Typography>            
          </Grid>
        </Grid> */}
      </Grid>
    </>
  )
}

export default HomeFeature
