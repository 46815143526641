import { responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useRoutes } from 'react-router';
import ArticleAddPage from './pages/ArticleAddPage';
import HomePage from './pages/HomePage';
import routes from './routes'
import { theme } from './theme';

const dTheme = responsiveFontSizes(theme);

function App() {
  const content = useRoutes(routes);

  return (
    <>
      <ThemeProvider theme={dTheme}>
        {content}
      </ThemeProvider>
    </>
  );
}

export default App;
