import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/HomePage')));
const ArticleAddPage = Loadable(lazy(() => import('./pages/ArticleAddPage')));
const ArticleDeletePage = Loadable(lazy(() => import('./pages/ArticleDeletePage')));
const ArticleUpdatePage = Loadable(lazy(() => import('./pages/ArticleUpdatePage')));
const InterviewAddPage = Loadable(lazy(() => import('./pages/InterviewAddPage')));
const InterviewDeletePage = Loadable(lazy(() => import('./pages/InterviewDeletePage')));
const InterviewUpdatePage = Loadable(lazy(() => import('./pages/InterviewUpdatePage')));
const ArticlePage = Loadable(lazy(() => import('./pages/ArticlePage')));
const MixedMediaPage = Loadable(lazy(() => import('./pages/MixedMediaPage')));
const ComingSoonPage = Loadable(lazy(() => import('./pages/ComingSoonPage')));
const ApplicationGatePage = Loadable(lazy(() => import('./pages/ApplicationGatePage')));
const Application01Page = Loadable(lazy(() => import('./pages/Application01Page')));
const ApplicationPage = Loadable(lazy(() => import('./pages/ApplicationPage')));
const AdminApplicantsPage = Loadable(lazy(() => import('./pages/AdminApplicantsPage')));
const NotFoundPage = Loadable(lazy(() => import('./pages/NotFoundPage')));
const SignaturesPage = Loadable(lazy(() => import('./pages/SignaturesPage')));
const HistoryPage = Loadable(lazy(() => import('./pages/_sections/HistoryPage')));
const AboutPage = Loadable(lazy(() => import('./pages/_sections/AboutPage')));
const HippieTimelinePage = Loadable(lazy(() => import('./pages/HippieTimelinePage')));
const HippieTypesPage = Loadable(lazy(() => import('./pages/HippieTypesPage')));
const HippieEvidencePage = Loadable(lazy(() => import('./pages/HippieEvidencePage')));
const HippieDangerPage = Loadable(lazy(() => import('./pages/HippieDangerPage')));
const HippieTypeDetailsPage = Loadable(lazy(() => import('./pages/HippieTypeDetailsPage')));
const AwarenessPage = Loadable(lazy(() => import('./pages/AwarenessPage')));
const ServicesTemplatePage = Loadable(lazy(() => import('./pages/ServicesTemplatePage')));
const ProductsPage = Loadable(lazy(() => import('./pages/ProductsPage')));
const ProductAddPage = Loadable(lazy(() => import('./pages/ProductAddPage')));
const ProductDeletePage = Loadable(lazy(() => import('./pages/ProductDeletePage')));
const ProductUpdatePage = Loadable(lazy(() => import('./pages/ProductUpdatePage')));

const routes = [
  { path: '/', element: <HomePage /> },
  { path: 'home', element: <HomePage /> },
  { path: 'application', element: <ApplicationGatePage /> },
  { path: 'application/apply', element: <Application01Page /> },
  { path: 'add-article', element: <ArticleAddPage /> },
  { path: 'delete-article', element: <ArticleDeletePage /> },
  { path: 'update-article', element: <ArticleUpdatePage /> },
  { path: 'add-interview', element: <InterviewAddPage /> },
  { path: 'delete-interview', element: <InterviewDeletePage /> },
  { path: 'update-interview', element: <InterviewUpdatePage /> },
  { path: 'admin/applicants', element: <AdminApplicantsPage /> },
  { path: 'articles/:articleId', element: <ArticlePage /> },
  { path: 'application/applications/:applicationId', element: <ApplicationPage /> },
  { path: 'signature', element: <SignaturesPage /> },
  { path: 'history', element: <HistoryPage /> },
  { path: 'types', element: <HippieTypesPage /> },
  { path: 'evidence', element: <HippieEvidencePage /> },
  { path: 'danger', element: <HippieDangerPage /> },
  { path: 'types/:typeId', element: <HippieTypeDetailsPage /> },
  { path: 'about', element: <AboutPage /> },
  { path: 'awareness', element: <AwarenessPage /> },
  { path: 'timeline', element: <HippieTimelinePage /> },
  { path: 'services/:serviceTag', element: <ServicesTemplatePage /> },
  { path: 'products', element: <ProductsPage /> },
  { path: 'products-add', element: <ProductAddPage /> },
  { path: 'products-delete', element: <ProductDeletePage /> },
  { path: 'products-update', element: <ProductUpdatePage /> },
  // {
  //   path: '401',
  //   element: <AuthorizationRequired />
  // },
  // {
  //   path: '404',
  //   element: <NotFound />
  // },
  // {
  //   path: '500',
  //   element: <ServerError />
  // },
  {
    path: 'applications/*',
    element: <NotFoundPage />
  },
  {
    path: '/*',
    element: <NotFoundPage />
  }
];

export default routes;
