import { useTheme } from '@emotion/react'
import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import HomeContent from '../components/home/HomeContent'
import HomeFeature from '../components/HomeFeature'
import MainFooter from '../components/MainFooter'
import MainNavbar from '../components/MainNavbar'
import PageTemplate from './PageTemplate'
import HomeItemTemplate from '../components/home/HomeItemTemplate'
import { landingCopy } from '../lib/Copy'
import { home, unsplash } from '../lib/Images'
import WelcomeDialog from '../components/WelcomeDialog'
// import PageTemplate from './PageTemplate'

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {defaultMatches: true})
  const { proof, education, history, danger, types, cta } = landingCopy;



  const HomeComponents = () => (
    <>
      <Grid container sx={{ minHeight: '100vh' }}>
        <MainNavbar />
        <HomeFeature className="Home Feature" topMargin={isMobile ? 0 : 0} />
        <HomeItemTemplate
          item={types} 
          img="https://cdn.midjourney.com/ecac187d-3942-4b6b-9a25-26d98df7225c/0_0.webp" //{unsplash.hippy_01}
          url="/types"
        />
        <HomeItemTemplate
          alt
          item={education}
          img={home.education}
          url="/education"
        />
        <HomeItemTemplate
          item={history}
          img={unsplash.hippy_03}
          url="/timeline"
          />
        <HomeItemTemplate
          alt
          item={danger}
          img={unsplash.hippy_04}
          url="/danger"
          />
        <HomeItemTemplate
          item={proof}
          img={unsplash.hippy_01}
          url="/evidence"
        />
        {/* <Grid
          container
          justifyContent="center"
          py={2}
          xs={12}
        >
          <HomeContent />
        </Grid> */}
        <MainFooter />
      </Grid>
      {/* <WelcomeDialog /> */}
    </>
  )
  return (
    // <>
      HomeComponents()
      // <PageTemplate className="Page Template" title="Home" tp={0.0001} content={HomeComponents()} />
    // </>
  )
}

export default HomePage
