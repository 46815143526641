import React, { useState } from 'react'
import { addDoc, collection } from '@firebase/firestore'
import { db } from '../firebase';
import { Button, Grid, TextField } from '@mui/material';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const addOptions = {
  categories: ['General', 'Profile', 'Studio', 'New Work'],
  tags: ['Art', 'Design', 'Motion', 'Experimental', 'Photography']
}

const fieldFormat = {
  backgroundColor: 'white',
  mt: 2
}



const ArticleAdd = () => {
  const [ addNew, setAddNew ] = useState({
    category: '',
    title: '',
    publication: '',
    author: '',
    publishDate: '',
    featuredImg: '',
    link: '',
    excerpt: ''
  });

  const handleNew = async () => {
    try {
      const collectionRef = collection(db, 'articles');
      const payload = {
        category: addNew.category || '',
        title: addNew.title || '',
        publication: addNew.publication || '',
        author: addNew.author || '',
        publishDate: addNew.publishDate || '',
        featuredImg: addNew.featuredImg || '',
        link: addNew.link || '',
        excerpt: addNew.excerpt || ''
      }
      
      await addDoc(collectionRef, payload);
      setAddNew({
        category: '',
        title: '',
        publication: '',
        author: '',
        publishDate: '',
        featuredImg: '',
        link: '',
        excerpt: ''
      });
    } catch (error) {

    }

  }

  const handleCKEditor = (e, editor) => {
    const data = editor.getData();
    setAddNew({ ...addNew, excerpt: data })
    // console.log('ckeditor data', data);
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent:'center',
        }}
      >
        <Grid
          item
          container
          sx={{
            justifyContent:'center',
            backgroundColor: '#eeffff',
            borderRadius: 3,
            p: 2
          }}
          xs={6}
        >
          {/* {console.log('addNew:', addNew)} */}
          <Grid
            item
            xs={8}
          >
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Title"
              value={addNew.title}
              onChange={e => setAddNew({ ...addNew, title: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Category"
              select
              SelectProps={{ native: true }}
              value={addNew.category}
              onChange={e => setAddNew({ ...addNew, category: e.target.value })}
            >
              {addOptions.categories.map((category) => (
                <option
                  key={category}
                  value={category}
                >
                  {category}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Publication"
              value={addNew.publication}
              onChange={e => setAddNew({ ...addNew, publication: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Author"
              value={addNew.author}
              onChange={e => setAddNew({ ...addNew, author: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Published Date"
              type="date"
              InputLabelProps={{
                shrink:true
              }}
              value={addNew.publishDate}
              onChange={e => setAddNew({ ...addNew, publishDate: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Link To Article"
              value={addNew.link}
              onChange={e => setAddNew({ ...addNew, link: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              label="Featured Image URL"
              type="url"
              value={addNew.featuredImg}
              onChange={e => setAddNew({ ...addNew, featuredImg: e.target.value })}
            />
            <TextField
              fullWidth
              sx={fieldFormat}
              InputLabelProps={{
                shrink:true
              }}
              label="Excerpt (Enter below)"
              placeholder="Edit below"
              disabled
              multiline
              maxRows={4}
              value={addNew.excerpt ? addNew.excerpt : ''}
              onChange={e => setAddNew({ ...addNew, excerpt: e.target.value })}
            />
            <CKEditor
              editor={ClassicEditor}
              onInit={ editor => addNew.excerpt }
              onChange={handleCKEditor}
            
            />
          </Grid>

          <Grid item container direction="column" alignContent="center" justifyContent="center" paddingX={3} paddingTop={2} xs={4}>
              <Button fullWidth sx={{p:3, lineHeight:1 }} variant="contained" onClick={handleNew}>Submit Article</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ArticleAdd
