import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export const useScreenSize = () => {
  const theme = useTheme();


  const isXL = useMediaQuery(theme.breakpoints.up('xl'), {defaultMatches: false})
  const isLG = useMediaQuery(theme.breakpoints.down('xl'), {defaultMatches: false})
  const isMD = useMediaQuery(theme.breakpoints.down('lg'), {defaultMatches: false})
  const isSM = useMediaQuery(theme.breakpoints.down('md'), {defaultMatches: false})
  const isXS = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: false})
  const ists = useMediaQuery(theme.breakpoints.down('xs'), {defaultMatches: false})

  // console.log('theme breakpoints', theme.breakpoints);

  // console.log({
  //   XL: isXL,
  //   LG: isLG,
  //   MD: isMD,
  //   SM: isSM,
  //   XS: isXS,
  //   ts: ists
  // })

  const screenSize = {
    xl: isXL,
    lg: isLG,
    md: isMD,
    sm: isSM,
    xs: isXS,
    ts: ists
  }

  return (screenSize)
}