import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4151a3',
      dark: '#133e70'
    },
    secondary: {
      main: '#69be5b',
      dark: '#091353'
      // contrastText: '#fff'
    },
    dark: {
      main: '#000'
    },
    light: {
      main: '#fff'
    },
    background: {
      primary: '#fff',
      secondary: '#efefef'
    }
  },
  typography: {
    fontFamily: 'Barlow',
    lineHeight: 1,
    h1: {
      fontSize:160
    },
    h2: {
      fontFamily:'Barlow',
      fontSize:90,
      fontWeight:'700',
      lineHeight:.9,
      textTransform:'uppercase',
      color:'#4151a3'
    },
    h4: {
      fontFamily:'Barlow Semi Condensed',
      fontSize:'24px',
      fontWeight:500,
      lineHeight: 1,
      color:'#555',
      textTransform:'capitalize'
    },
    h5: {
      fontFamily: 'Kanit',
      fontSize: '26px',
      fontWeight:300,
      lineHeight:1.4,
      color: '#666'
    },
    h6: {
      lineHeight: 1.1
    },
    body1: {
      fontFamily: 'Kanit',
      fontSize: '20px',
      fontWeight:300,
      lineHeight:1.4,
      color: '#666'
    },
    button: {
    },
    MuiTab: {
      fontFamily: 'Barlow'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            padding: '10px 20px',
            lineHeight: 1,
            fontSize:'2rem'
          },
        },
      ],
    },
  },
  breakpoint:{
    xs:380,
    sm:640,
    md:1024,
    lg:1400,
    xl:1920
  }
})