import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { landingCopy } from '../../lib/Copy';

const HomeItemTemplate = (props) => {
  const { item, img, url, alt } = props;

  return (
    <Grid
      container
      style={{
        minHeight:'50vh'
      }}
    >
      <Grid
        item
        order={{xs:0, md:alt ? 1 : 0}}
        sx={{
          position:'relative',
          minHeight:{xs:250, md:'auto'}
        }}
        xs={12}
        md={6}
      >
        <img
          src={img}
          style={{
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100%',
            objectFit:'cover'
          }}
        />
      </Grid>
      <Grid
        item
        style={{
          padding:48
        }}
        xs={12}
        md={6}
      >        
        <Typography
          variant="h2"
          sx={{
            fontSize:{xs:36, sm:60, lg:90},
            transition:'.4s'
          }}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            fontSize:{xs:'1.2rem' , sm:'1.3rem' , lg:'1.5rem'},
            lineHeight:{xs:'1.3rem' , sm:'1.7rem' , lg:'2rem'},
            transition:'.4s',
            fontWeight:500,
            marginTop:1
          }}
        >
          {item.body}
        </Typography>
        <Button
          variant="contained"
          href={url}
          style={{
            marginTop:16
          }}
        >
          {item.cta}
        </Button>
      </Grid>
    </Grid>
  )
}

export default HomeItemTemplate