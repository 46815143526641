import React from 'react'
import { Grid, Typography } from '@mui/material'
import HARIcon from '../assets/HAR_Icon_1_BlueGreen.svg'

const MainFooter = () => {
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: '#000',
          height: '80px',
          justifyContent: 'center',
          alignItems: 'center',
          p:1,
          overflow: 'hidden'
        }}
        xs={12}
      >
          <Grid container alignItems="center" justifyContent="center" display="flex">
            <img
              src={HARIcon}
              style={{
                height:'50px',
                marginRight:15
              }}
              alt=""
            />
            <Typography variant="subtitle1" color="white" mx={2}>Copyright 2023</Typography>
            <Typography variant="subtitle1" color="white">Hippies Aren't Real</Typography>
          </Grid>
      </Grid>
    </>
  )
}

export default MainFooter
