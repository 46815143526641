import { Grid } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet-async';
import MainFooter from '../components/MainFooter';
import MainNavbar from '../components/MainNavbar';

const PageTemplate = (props) => {
  const { tp, title, noNavbar, noFooter, content, noPad } = props;

  return (
    <>
      <Helmet>
        <title>{title} | Hippies Aren't Real</title>
      </Helmet>
      <MainNavbar />
      <Grid
        className="PageTemplate Grid Top"
        container
        sx={{
          minHeight: 'calc(100vh - 46px)',
          backgroundColor: 'white',
          justifyContent:'center',
          alignContent: 'space-between',
          mt: tp ? tp : 0
        }}
      >
        <Grid
          className="PageTemplate Grid item container"
          item
          container
          sx={{
            justifyContent:'center',
            alignContent:'flex-start',
            maxWidth:noPad ? '100%' : 1200
          }}
          xs={noPad ? 12 : 10}
        >
          {content}        
        </Grid>
        {
          !noFooter && <MainFooter />
        }
      </Grid>
    </>
  )
}

export default PageTemplate
